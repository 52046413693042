$.noConflict();
     
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = escape(name) + "=" + escape(value) + expires + "; path=/";
}

function deobf(name, domain, rot) {
    return rotate(name, -rot) + "@" + rotate(domain, -rot);
}

function rotate(message, rot) {
    var coded = "";
    for (i = 0; i < message.length; i++) {
        var ch = message.charCodeAt(i);
        coded += String.fromCharCode(ch + rot);
    }
    return coded;
}

jQuery(document).ready(function($) {       
    $(".js_show").show();
    $(".js_hide").hide();
    $("body").removeClass("nojs");

    $(":not(.lmeditor_inline) a[data-name]").each(function () {
        var a = $(this);
        var n = deobf(a.data("name"), a.data("dmn"), parseInt(a.data("rot"), 10));
        a.attr("href", "mailto:" + n).text(n);
    });

    $(".link_block").prepend("<span class='glyphicon glyphicon-chevron-right hidden-print'></span>");
    $(".link_block_xs").prepend("<span class='glyphicon glyphicon-chevron-right visible-xs hidden-print'></span>");
    $(".link_block_sm").prepend("<span class='glyphicon glyphicon-chevron-right visible-sm visible-xs hidden-print'></span>");
    $(".link_block_md").prepend("<span class='glyphicon glyphicon-chevron-right visible-sm visible-xs visible-md hidden-print'></span>");
    $(".link_block_lg").prepend("<span class='glyphicon glyphicon-chevron-right visible-sm visible-xs visible-md visible-lg hidden-print'></span>");

    $("a[onclick^=_gaq]").removeAttr("onclick");

    $(document).on("click", "a", function () {
        var href = $(this).attr("href");

        if (href && href != "") {
            if (href.indexOf("http") !== -1) {
                if (typeof ga !== "undefined") {
                    href = href.replace(/https?:\/\//ig, "");
                    ga("send", "pageview", '/external/' + href);
                }
            } else if (href.indexOf(".pdf") !== -1 || href.indexOf(".doc") !== -1) {
                if (typeof ga !== "undefined") {
                    href = href.replace(/https?:\/\//ig, "");
                    if (href[0] == '/') href = href.substring(1);
                    ga("send", "pageview", '/document/' + href);
                }
            } else if (href.indexOf("mailto:") !== -1) {
                if (typeof ga !== "undefined") {
                    href = href.replace(/mailto:/ig, "");
                    if (href[0] == '/') href = href.substring(1);
                    ga("send", "pageview", '/mailto/' + href);
                }
            }
        }
    });

    if (readCookie("UseDesktop") != "true" && window.screen.availWidth && window.screen.availWidth < 768) {
        $("#responsive_meta").attr("content", "user-scalable=no, initial-scale=1.0, maximum-scale=1.0, width=device-width");
        createCookie("smallScreen", "true", 365);
    } else {
        createCookie("smallScreen", "false", 365);
    }

    $(window).bind("load resize", function () {
        $(".module_featured_image img, .module_inline_image img").each(function () {
            $(this).css("margin-left", -(Math.floor(($(this).width() / 2) + 0.5) - ($(this).parent().width() / 2)) + "px");
        });

        if ($("#footer_nav").length > 0) {
            var navLeft = $("#footer_nav").offset().left;

            $("#footer_nav .container > ul > li").not(".li_nav_home").removeAttr("style");

            $("#footer_nav .container > ul > li").not(".li_nav_home").each(function () {
                if ($(this).offset().left <= navLeft) {
                    $(this).css({
                        "clear": "both",
                        "margin-left": "0px"
                    });

                    navLeft = $("#footer_nav").offset().left;
                } else {
                    navLeft = $(this).offset().left;
                }
            });
        }

        var width = $(window).width();

        if (width == $(window).data("width")) {
            return;
        }

        $(window).data("width", width);

        if ((window.matchMedia && window.matchMedia("screen and (max-width : 767px)").matches) || (!window.matchMedia && window.screen.availWidth && window.screen.availWidth < 768)) {

        } else {

        }
    });

    //Open translation function
    $('.reveal_translation').click(function () {
        $('.hidden_translation').toggleClass("opened");
    })

    if (extraJQueryFunctions.length > 0) {
        for (var i = 0; i < extraJQueryFunctions.length; i++) {
            extraJQueryFunctions[i]($);
        }
    }
});