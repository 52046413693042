
function validateInt(input, args) { }
function validateDecimal(input, args) { }
function validateDate(input, args) { }

extraJQueryFunctions.push(function ($) {
    // Extend .Net validation javascript to allow adding and removing bootstrap error classes from a control when an associated validator fails
    if (typeof (Page_ClientValidate) != "undefined") {
        AspValidatorUpdateDisplay = ValidatorUpdateDisplay;
        AspValidatorValidate = ValidatorValidate;

        ValidatorUpdateDisplay = function (val) {
            AspValidatorUpdateDisplay(val);

            if ((typeof (val.enabled) == "undefined" || val.enabled != false) && IsValidationGroupMatch(val, AspValidatorValidating)) {
                if ($("#" + val.controltovalidate).parents(".form-group").length > 0) {

                    var formGroup = $("#" + val.controltovalidate).parents(".form-group").first();

                    var currentType = "unknown";

                    var allTypes = [
                        {
                            evalFunction: RequiredFieldValidatorEvaluateIsValid,
                            type: "required-field"
                        },
                        {
                            evalFunction: RegularExpressionValidatorEvaluateIsValid,
                            type: "regex-field"
                        },
                        {
                            evalFunction: RangeValidatorEvaluateIsValid,
                            type: "range-field"
                        },
                        {
                            evalFunction: CustomValidatorEvaluateIsValid,
                            type: "custom-field"
                        },
                        {
                            evalFunction: CompareValidatorEvaluateIsValid,
                            type: "compare-field"
                        }
                    ];

                    for (var i = 0; i < allTypes.length; i++) {
                        if (val.evaluationfunction == allTypes[i].evalFunction) {
                            currentType = allTypes[i].type;
                        }
                    }

                    if (val.isvalid) {
                        formGroup.removeClass("has-error-" + currentType);
                    } else {
                        formGroup.addClass("has-error has-feedback has-error-" + currentType);
                    }

                    var anyErrorsFound = false;

                    for (var a = 0; a < allTypes.length; a++) {
                        if (formGroup.hasClass("has-error-" + allTypes[a].type)) {
                            anyErrorsFound = true;
                        }
                    }

                    if (!anyErrorsFound) {
                        formGroup.removeClass("has-error has-feedback");
                    }
                }
            }
        };

        ValidatorValidate = function (val, validationGroup, event) {
            AspValidatorValidating = validationGroup;
            AspValidatorValidate(val, validationGroup, event);
        };
    }

    validateInt = function (input, args) {
        args.IsValid = true;
        if (/^\-?\d+$/ig.exec(document.getElementById(input.controltovalidate).value) == null) {
            args.IsValid = false;
        }
        return args.IsValid;
    };

    validateDecimal = function (input, args) {
        args.IsValid = true;
        if (/^\-?\d+(\.\d+)?$/ig.exec(document.getElementById(input.controltovalidate).value) == null) {
            args.IsValid = false;
        }
        return args.IsValid;
    };

    validateDate = function (input, args) {
        args.IsValid = true;
        if (/^[0-3]?\d\/((0?[1-9])|(1[0-2]))\/\d{2,4}\s(([0-1]?[0-9])|(2[0-4])):(([0-5][0-9])|(60))$/ig.exec(document.getElementById(input.controltovalidate).value) == null) {
            args.IsValid = false;
        }
        return args.IsValid;
    };

    validateFile = function (input, args) {
        var textBox = $("#" + input.controltovalidate);
        var filePath = textBox.val();

        if (filePath == "") {
            args.isValid = true;
            return;
        }

        var fileName = filePath.split("/").pop();

        var errorMessage = "";

        var fileExtension = "." + fileName.split(".").pop();

        var acceptedFileExtensions = textBox.data("acceptedFileExtensions");
        var acceptedFileExtensionsString = "";

        for (var i = 0; i < acceptedFileExtensions.length; i++) {
            acceptedFileExtensionsString += acceptedFileExtensions[i];

            if (i < (acceptedFileExtensions.length - 1)) {
                acceptedFileExtensionsString += ", ";
            }
        }

        var inWhiteList = false;

        for (var a = 0; a < acceptedFileExtensions.length; a++) {
            if (fileExtension == acceptedFileExtensions[a]) {
                inWhiteList = true;
            }
        }

        if (!inWhiteList) {
            errorMessage = "Sorry, your file was of the wrong type, please choose a file with the following extensions: '" + acceptedFileExtensionsString + "'.";
        }

        if (errorMessage != "") {
            args.IsValid = false;
            input.errormessage = errorMessage;
            input.innerHTML = "<span class='help-block'>" + errorMessage + "</span>";
        }

        textBox.data("updateInfo")();
    };
});