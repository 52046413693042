extraJQueryFunctions.push(function ($) {
    $(window).bind("load resize", function () {
        $(".info_boxes").each(function () {
            var maxHeight = 0;
            var topOffset = $(this).offset().top;
            var currentBoxes = [];
            var offsetBoxes = 0;
            var totalBoxes = 0;

            var allBoxes = $(this).find(".info_box");

            allBoxes.css("height", "").each(function () {
                if ($(this).offset().top > topOffset) {
                    if (totalBoxes > 0) {
                        for (var i = offsetBoxes; i < (totalBoxes + offsetBoxes) ; i++) {
                            $(allBoxes.get(i)).css("height", (maxHeight + 55) + "px");
                        }
                    }

                    maxHeight = 0;
                    offsetBoxes += totalBoxes;
                    totalBoxes = 0;

                    topOffset = $(this).offset().top;
                }

                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }

                currentBoxes[totalBoxes] = $(this);
                totalBoxes++;
            });

            if (totalBoxes > 0) {
                for (var i = offsetBoxes; i < (totalBoxes + offsetBoxes) ; i++) {
                    $(allBoxes.get(i)).css("height", (maxHeight + 55) + "px");
                }
            }
        });
    });
});