extraJQueryFunctions.push(function ($) {
    $(".share_buttons a").each(function () {
        var button = $(this);
        button.click(function (e) {

            var windowWidth = $(window).width();
            var windowHeight = $(window).height();

            var width = Math.min(400, windowWidth);
            var height = Math.min(400, windowHeight);

            var left = windowWidth / 2 - width / 2;
            var top = windowHeight / 2 - height / 2;

            var buttons = button.closest(".share_buttons");
            var title = buttons.data("title");
            var text = buttons.data("text");
            var url = buttons.data("url");

            switch (button.data("network")) {
                case "facebook":
                    var win = window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url) + '&t=' + encodeURIComponent(title), '_blank', "left=" + left + ",top=" + top + ",width=" + width + ",height=" + height + ",status=0,toolbar=0,titlebar=0,location=0,modal=1");
                    win.moveTo(left, top);
                    e.preventDefault();
                    break;
                case "twitter":
                    var win = window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(text) + ' ' + encodeURIComponent(url), '_blank', "left=" + left + ",top=" + top + ",width=" + width + ",height=" + height + ",status=0,toolbar=0,titlebar=0,location=0,modal=1");
                    win.moveTo(left, top);
                    e.preventDefault();
                    break;
                case "google":
                    var win = window.open('https://plus.google.com/share?url=' + encodeURIComponent(url), '_blank', "left=" + left + ",top=" + top + ",width=" + width + ",height=" + height + ",status=0,toolbar=0,titlebar=0,location=0,modal=1");
                    win.moveTo(left, top);
                    e.preventDefault();
                    break;
                case "email":
                    location = "mailto:?subject="+encodeURIComponent(title)+"&body="+encodeURIComponent(text)+" "+encodeURIComponent(url)+"";
                    e.preventDefault();
                    break;
            }

        });
    });
});