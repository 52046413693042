extraJQueryFunctions.push(function ($) {
    $(".search_trigger").click(function (event) {
        event.preventDefault();
        $(".search_layer").fadeIn(200, function () {
            $(".search_layer input[type=text]").first().focus();
        });
    });

    $(".search_close").click(function (event) {
        event.preventDefault();
        $(".search_layer").fadeOut(200);
    });

    $('.collections_category_list li:contains("Coventry Collections Website")').hide();
});